import React, { useEffect, useState } from "react";
import "../css/Layout.css"; // Optional: You can have additional styles for your layout
import { Link, useLocation } from "react-router-dom";

const DeviceLayout = ({ children }) => {
  const location = useLocation(); // Get the current location
  const [description, setDescription] = useState("");
  const [timeElapsed, setTimeElapsed] = useState("");
  const [isEditing, setIsEditing] = useState(false); // Track editing state
  const [newDescription, setNewDescription] = useState(""); // Track new description

  const fetchData = async () => {
    const id = getDeviceIdFromUrl(); // Get device ID from URL
    if (id) {
      try {
        const response = await fetch(`/api/device/desc/${id}`);
        const data = await response.json();
        if (data.description == "") {
          setDescription("N/A");
        } else {
          setDescription(data.description);
        }
        setNewDescription(data.description); // Initialize newDescription
        setTimeElapsed(data.updated);
      } catch (error) {
        console.error("Error fetching device data:", error);
      }
    } else {
      console.error("Device ID not found in the URL.");
    }
  };

  const getDeviceIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true); // Start editing
  };

  const handleInputChange = (e) => {
    setNewDescription(e.target.value); // Update new description state
  };

  const handleUpdateDescription = async () => {
    const id = getDeviceIdFromUrl(); // Get device ID from URL
    try {
      const response = await fetch("/api/update_user_description", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: id,
          user_description: newDescription,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setDescription(newDescription); // Update the displayed description
        setIsEditing(false); // Exit editing mode
        alert(data.message); // Show success message
      } else {
        const errorData = await response.json();
        alert(errorData.error); // Show error message
      }
    } catch (error) {
      console.error("Error updating description:", error);
    }
  };

  // Extract the query string (e.g., ?id=1CA4)
  const searchParams = new URLSearchParams(location.search);
  const currentId = searchParams.get("id"); // Extract the "id" parameter

  return (
    <div className="frame">
      <div className="topdevice">
        <div className="frame-buttons">
          <Link to={`/device/data?id=${currentId}`} className="frame-button">
            Info
          </Link>
          <Link to={`/device/detail?id=${currentId}`} className="frame-button">
            Detail
          </Link>
          <Link to={`/device/history?id=${currentId}`} className="frame-button">
            History
          </Link>
          <Link to={`/device/events?id=${currentId}`} className="frame-button">
            Events
          </Link>
          {/*<Link to={`/device/control?id=${currentId}`} className="frame-button">Control</Link>*/}
        </div>
        {/* Description section */}
        {isEditing ? (
          <div>
            <input
              type="text"
              value={newDescription}
              onChange={handleInputChange}
              placeholder="Enter new description"
            />
            <button onClick={handleUpdateDescription}>Save</button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
          </div>
        ) : (
          <div>
            <div className="Title" id="Title" onClick={handleEditClick}>
              {description}
            </div>
          </div>
        )}
        <div>Last data received: {timeElapsed}</div>
      </div>
      {children} {/* This will render the child content inside the frame */}
    </div>
  );
};

export default DeviceLayout;
