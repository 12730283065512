import React from "react";
import "./css/App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "./Layout";
import DeviceList from "./components/DeviceList";
import DeviceData from "./components/DeviceData";
import DeviceDetail from "./components/DeviceDetail";
import LoginForm from "./components/LoginForm";
import Registration from "./components/Register";
import DeviceHistory from "./components/DeviceHistory";
import DeviceEvents from "./components/DeviceEvents";
import DeviceConfig from "./components/DeviceConfig";
import Admin from "./components/Admin";


function App() {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const hasSeenAlert = localStorage.getItem("hasSeenAlert");
    if (!hasSeenAlert) {
      //setShowAlert(true); // Show the alert
    }
  }, []);

  const dismissAlert = () => {
    setShowAlert(false); // Hide the alert
    localStorage.setItem("hasSeenAlert", "true"); // Mark as seen
  };

  return (
    <Router>
      <Layout>
        <div className="App">
        {showAlert && (
      <div className="apology-banner">
        We apologize for the server downtime over the weekend. We're addressing the issue to ensure it doesn't happen again. Thank you for your understanding!
        <button className="dismiss-button" onClick={dismissAlert}>
          Dismiss
        </button>
      </div>
    )}
          <Routes>
            <Route path="/" element={<DeviceList />} /> {/* Home Page */}
            <Route path="/admin" element={<Admin />} /> {/* Login Page */}
            <Route path="/login" element={<LoginForm />} /> {/* Login Page */}
            <Route path="/register" element={<Registration />} />
            <Route path="/device/history" element={<DeviceHistory />} />{" "}
            <Route path="/device/data" element={<DeviceData />} />{" "}
            <Route path="/device/detail" element={<DeviceDetail />} />{" "}
            <Route path="/device/events" element={<DeviceEvents />} />{" "}
            <Route path="/device/config" element={<DeviceConfig />} />{" "}
          </Routes>
        </div>
      </Layout>
    </Router>
  );
}

export default App;
