import React, { useState, useEffect } from "react";
import DeviceLayout from "./DeviceLayout";

const DeviceConfig = ({ deviceId }) => {
  const [configData, setConfigData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchConfig();
  }, []);
  const fetchWithTimeout = async (url, options, timeout = 15000) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      clearTimeout(id);
      return response;
    } catch (err) {
      clearTimeout(id);
      throw err;
    }
  };
  
  // Usage in fetchConfig
  const fetchConfig = async () => {
    const id = getDeviceIdFromUrl();
    if (id) {
      try {
        setLoading(true);
        setError(null);
  
        const response = await fetchWithTimeout(`/api/config/${id}`, {}, 15000); // 15-second timeout
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
  
        const data = await response.json();
        setConfigData(data);
        setFormData(data); // Initialize form data with fetched config
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Device ID not found in the URL.");
    }
  };
  const getDeviceIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  };

  const handleInputChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    const id = getDeviceIdFromUrl();
    if (id) {
      try {
        setLoading(true);
        setError(null);

        const response = await fetch(`/api/config/${id}`, {
          method: "POST", // Change to POST if necessary
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error(`Error updating data: ${response.statusText}`);
        }

        const updatedData = await response.json();
        setConfigData(updatedData);
        setEditMode(false);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Device ID not found in the URL.");
    }
  };

  if (loading) {
    return <p>Loading configuration...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!configData) {
    return <p>No configuration data found.</p>;
  }

  const renderTable = (data) => {
    const rows = Object.entries(data);

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  {editMode ? (
                    typeof value === "object" ? (
                      <textarea
                        value={JSON.stringify(formData[key], null, 2)}
                        onChange={(e) =>
                          handleInputChange(key, JSON.parse(e.target.value))
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        value={formData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      />
                    )
                  ) : typeof value === "object" ? (
                    <pre>{JSON.stringify(value, null, 2)}</pre>
                  ) : (
                    value.toString()
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <DeviceLayout>
      <div>
        {renderTable(editMode ? formData : configData)}
        <div style={{ marginTop: "20px" }}>
          {editMode ? (
            <>
              <button onClick={handleSubmit}>Save</button>
              <button onClick={() => setEditMode(false)}>Cancel</button>
            </>
          ) : (
            <button onClick={() => setEditMode(true)}>Edit</button>
          )}
        </div>
      </div>
    </DeviceLayout>
  );
};

export default DeviceConfig;
