import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// DeviceList Component
export default function DeviceList() {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await fetch("/api/devices");
      const data = await response.json();
      setDevices(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Run fetchData when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (
    
    <div>
            {loading ? (
        <div className="spinner-container"><div className="spinner"></div></div> // Replace with a spinner component or CSS loader
      ) : (
      <table>
        <thead>
          <tr>
            <th className="dot-container"></th>
            <th>Device</th>
            <th>Voltage</th>
            <th>SOC</th>
            <th>Power</th>
            <th>Status</th>
            <th>ID</th>
            <th>Last update</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => (
            <DeviceRow key={device.device_id} device={device} />
          ))}
        </tbody>
      </table>
      )}
    </div>  
  );
}

// DeviceRow Component
function DeviceRow({ device }) {
  const {
    description,
    Voltage,
    SOC,
    Power,
    Status,
    device_id,
    updated,
    online,
  } = device;
  const navigate = useNavigate(); // Initialize the navigate function from React Router
  let backgroundColor = "#fff";
  if (Status === "Warning") backgroundColor = "#ff9";
  if (Status === "Protect") backgroundColor = "#f99";
  if (Status === "Running") backgroundColor = "#9f9";
  if (!online || Status === "") {
    backgroundColor = "#aaa";
    device.Status = "Disconnected";
  }
  const handleRowClick = () => {
    const encodedId = encodeURIComponent(device_id);
    navigate(`/device/data?id=${encodedId}`); // Use navigate for routing
  };

  return (
    <tr onClick={handleRowClick} style={{ cursor: "pointer", backgroundColor }}>
      <td>
        <div className="dot" style={{ backgroundColor }}></div>
      </td>
      <td>{description || "N/A"}</td>
      <td>{Voltage !== null ? Voltage * 1 : "N/A"} V</td>
      <td>{SOC !== null ? SOC * 1 : "N/A"} %</td>
      <td>{Power !== null ? (Power / 1000).toFixed(1) * 1 : "N/A"} kW</td>
      <td>{Status || "N/A"}</td>
      <td>{device_id}</td>
      <td>{updated || "N/A"}</td>
    </tr>
  );
}
